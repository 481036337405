import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Commands`}</h2>
    <h3>{`Find largest directories`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`du -a | sort -nr | head -n 5
`}</code></pre>
    <h3>{`Run previous command with sudo`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo !!
`}</code></pre>
    <h3>{`Exit session but leave process running`}</h3>
    <pre><code parentName="pre" {...{}}>{`disown -a && exit
`}</code></pre>
    <h2>{`Troubleshooting`}</h2>
    <h3>{`Fix access to /dev/dma_heap denied`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo setenforce 0
sudo chcon system_u:object_r:device_t:s0 /dev/dma_heap/
sudo setenforce 1
`}</code></pre>
    <h3>{`Fix cgroup mount destination: unknown`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo mkdir /sys/fs/cgroup/systemd
sudo mount -t cgroup -o none,name=systemd cgroup /sys/fs/cgroup/systemd
`}</code></pre>
    <h3>{`Set SELinux to permissive mode`}</h3>
    <p><strong parentName="p">{`Permanent`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "path=/etc/selinux/config",
        "path": "/etc/selinux/config"
      }}>{`SELINUX=permissive
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`reboot
`}</code></pre>
    <p><strong parentName="p">{`Until reboot`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo setenforce 0
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      